import { P2 } from "components/atoms/Paragraph";
import { FC } from "react";
import styled from "styled-components";
import { color, fonts } from "../../styles";
import { YuLifeLocale, useLocale } from "hooks";
import { Link } from "@components/atoms";
import Image from "next/image";
import { string } from "yup";
import { mobile } from "components/templates/Breakpoints";
interface ProductCardProps {
  title: string;
  iconName?: string;
  href?: string;
  description: string;
  width?: string;
  height?: string;
  justifyContent?: string;
  hasPadding?: boolean;
  titleWidth?: string;
}

const getPaddingByLocale = (locale: YuLifeLocale) => {
  switch (locale) {
    case "us":
      return "2rem";
    case "za":
      return "3.5rem 2rem";
    case "uk":
    default:
      return "3rem 2rem 3.5rem";
  }
};

export const ProductCard: FC<ProductCardProps> = ({
  title,
  iconName,
  href,
  description,
  width,
  height,
  justifyContent = "space-around",
  hasPadding = true,
  titleWidth
}) => {
  const { locale } = useLocale();
  const iconSize = locale === "us" ? 32 : 40

  const cardContent = (
    <>
      <TextWrap locale={locale}>
        {iconName ? (
          <Icon
            src={`/static/icons/${iconName}.svg`}
            width={iconSize}
            height={iconSize}
            alt={title}
            style={{ width: "auto", height: "auto" }}
            locale={locale}
          />
        ) : null}
        <TitleWrap $hasPadding={hasPadding} titleWidth={titleWidth}>
          <Title>{title}</Title>
        </TitleWrap>
        <Description>{description}</Description>
      </TextWrap>
      {href && <StyledCTO locale={locale}>Learn more</StyledCTO>}
    </>
  );

  return (
    <>
      {href ? (
        <Link href={href} passHref>
          <Card
            justifyContent={justifyContent}
            width={width}
            height={height}
            $paddingByLocale={getPaddingByLocale(locale)}
            href={href}
            locale={locale}
          >
            {cardContent}
          </Card>
        </Link>
      ) : (
        <Card
          justifyContent={justifyContent}
          width={width}
          height={height}
          $paddingByLocale={getPaddingByLocale(locale)}
          href={href}
          locale={locale}
        >
          {cardContent}
        </Card>
      )}
    </>
  );
};

const TextWrap = styled.div<{
  locale: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(p) => (p.locale === "us" ? "0" : "1rem")};
  margin-top: 1.875rem auto 0;
`;

const Card = styled.div<{
  width?: string;
  height?: string;
  $paddingByLocale?: string;
  href?: string;
  justifyContent?: string;
  locale?: string;
}>`
  box-sizing: border-box;
  padding: ${(p) => p.$paddingByLocale};
  gap: ${(p) => (p.width === "25rem" ? "0rem" : "1.5rem")};
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  border: 0.125rem solid ${color.B240};
  box-shadow: 0.5rem 0.5rem 0px ${color.B220};
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => p.justifyContent};
  background: white;
  position: relative;
  transition: 200ms ease-out;

  &:hover {
    ${(p) =>
      p.href
        ? `
      transform: translateY(-1rem);
      box-shadow: 0.9rem 0.9rem 0px ${color.B220};
    `
        : ""}
  }

  ${mobile}{
    height: auto;
    padding: ${(p) => p.locale === "uk" ? "2rem" : p.$paddingByLocale};
  }
`;

const Icon = styled(Image)<{
  locale: string
}>`
  flex-grow: 0;
  display: block;
  padding-bottom: ${(p) => (p.locale === "us" ? "1.5rem" : "0")}
`;

const Title = styled.strong`
  font-family: ${fonts.body};
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.075rem;
  color: ${color.H100};
`;

const StyledCTO = styled.p<{
  locale: string
}>`
  letter-spacing: 0.075rem;
  text-decoration-line: underline;
  color: ${color.P100};
  margin: ${(p) => (p.locale === "us" ? "0 auto" : "0.5rem auto 0")};
  font-size: 1rem;
`;

const Description = styled(P2)`
  text-align: center;
  padding-top: 0.5rem;
  line-height: 1.5rem;
`;

const TitleWrap = styled.div<{ $hasPadding?: boolean; titleWidth?: string; }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${(p) => (p.titleWidth ? p.titleWidth : "18rem")};
  padding: ${(p) => (p.$hasPadding ? "0 1.5rem" : "0")};
`;
